'use client';

import clsx from 'clsx';
import ProductCartButton from 'components/buttons/product-cart-button';
import ShippingMethodProductCart from 'components/buttons/product-cart-shipping-button';
import Image from 'next/image';
import { Link } from 'components/link';
import { useState } from 'react';
import { DiscountFilledIcon } from './icons';
import { ExpressIcon } from './icons/express';

import type { AlgoliaProduct } from 'types/product';
import { StarIcon, CircleCloseIcon, PlusIcon, ProductDayIcon } from './icon';

import { useTranslation } from 'app/i18n/client';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import FavoriteButton from 'components/cms/banners/product-carousel-banner/favorite-button';
import { setPresceriptionModal } from 'redux/features/presceription-modal';
import {
  setBundleModal,
  setBundleProductInfo,
} from 'redux/features/bundle-products';
import { CartShipmentType } from 'types/my-cart';
import { ChooseAlternativeButton } from './buttons/choose-alternative-button';
import { GlobalIcon } from './icons/global-icon';
import HydrationWrapper from './hydration-wrapper';
import { LanguageCodesType, RegionCodesType, getPdpUrl } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { gtmSetSelectItem } from 'lib/gtm';

export default function ProductItem({
  product,
  isOnWishlist,
  dualColumn,
  promoBadgeMessage,
  alternativeProducts,
  rates,
  inStock,
  zoneId,
  isStorePickup,
  hasBundleOffer,
  shipmentType,
  isProductDay,
  language,
  region,
}: {
  product: AlgoliaProduct;
  dualColumn?: boolean;
  isOnWishlist?: boolean;
  isGuestAddress?: number | undefined;
  isMiniProfileAddress?: boolean;
  promoBadgeMessage: string;
  alternativeProducts: [];
  rates: {
    avgRating: number;
    totalRatings: number;
  };
  inStock: boolean | undefined;
  isStorePickup: boolean;
  zoneId?: string;
  hasBundleOffer?: boolean;
  shipmentType: CartShipmentType;
  isProductDay?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [isFavorite, setIsFavorite] = useState(isOnWishlist ? true : false);
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'product-list-page'
  );
  const appDispatch = useAppDispatch();
  const { appSettings } = useAppSelector((state) => state.cmsReducer);
  const maxQuantityLimit =
    appSettings?.global_components?.basket?.max_qty_per_sku;
  if (!product) return;
  const isPrescription =
    Number(product.rx_items) > 0 || product.rx_items === 'Yes';

  function handleFavorite() {
    setIsFavorite(!isFavorite);
  }

  function bundleProductHandle() {
    appDispatch(setBundleModal(true));
    appDispatch(
      setBundleProductInfo({
        id: product.sku,
        rates: rates,
      })
    );
  }

  const reviewSection = () => {
    return (
      <div className='flex items-center space-x-1 rtl:space-x-reverse'>
        {rates?.avgRating?.toFixed(1) &&
          Number(rates?.avgRating?.toFixed(1)) > 0 && (
            <>
              <StarIcon className='h-4 w-4 text-primary-yellow' />
              <div className='flex items-center space-x-1 font-bold text-gray rtl:space-x-reverse'>
                <span className='text-[11px] font-semibold text-gray'>
                  {rates?.avgRating?.toFixed(1)} ({rates?.totalRatings ?? 0})
                </span>
              </div>
            </>
          )}
      </div>
    );
  };

  const deliverType = () => {
    const type = (
      isStorePickup ? product.global_filter : shipmentType
    )?.toLowerCase();
    switch (type) {
      case 'express':
        return (
          <div
            className='flex h-[21px] items-center space-x-1 rounded-3xl bg-red px-2 py-1 rtl:space-x-reverse'
            style={{ backgroundColor: 'rgba(65, 193, 138, 1)' }}
          >
            <ExpressIcon className='h-[12px] w-[15.692px] text-white' />
            <span className='text-[11px] font-bold text-white'>Express</span>
          </div>
        );
      case 'global':
        return <GlobalIcon className='h-[21px] text-white' />;
    }
  };

  const promoBadge = (arg: string) => {
    if (promoBadgeMessage) {
      return (
        <div className='flex items-center space-x-1 rounded-3xl bg-red py-1 pl-1 pr-2 rtl:space-x-reverse'>
          <DiscountFilledIcon className='h-[13px] w-[13px] text-white' />
          <span className='text-[11px] font-semibold text-white'>
            {promoBadgeMessage}
          </span>
        </div>
      );
    } else {
      return (
        <div
          className={`items-center space-x-1 rounded-3xl py-1 pl-1 pr-2 rtl:space-x-reverse ${
            arg === 'hidden' ? 'hidden' : 'flex'
          }`}
        >
          <br />
        </div>
      );
    }
  };

  const buttonCondition = () => {
    if (isPrescription) {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <button
            onClick={() => appDispatch(setPresceriptionModal(true))}
            className={clsx(
              'relative h-8 w-8 rounded-full bg-blue text-white',
              'flex items-center justify-center shadow-addButton transition-width duration-200',
              'group overflow-hidden'
            )}
          >
            <PlusIcon
              className={
                'has-color absolute h-4 w-4 fill-white ltr:right-2 rtl:left-2'
              }
            />
          </button>
        </div>
      );
    } else if (
      hasBundleOffer &&
      ((zoneId && inStock) || isStorePickup) &&
      !isPrescription
    ) {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <button
            onClick={() => bundleProductHandle()}
            className={clsx(
              'relative h-8 w-8 rounded-full bg-blue text-white',
              'flex items-center justify-center shadow-addButton transition-width duration-200',
              'group overflow-hidden'
            )}
          >
            <PlusIcon
              className={
                'has-color absolute h-4 w-4 fill-white ltr:right-2 rtl:left-2'
              }
            />
          </button>
        </div>
      );
    } else if (isStorePickup || (zoneId && inStock && !isPrescription)) {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <ProductCartButton
            product={{
              sku: product.sku,
              isGlobalProduct: product.global_filter === 'Global',
            }}
            maximumQuantity={
              maxQuantityLimit
                ? product.max_qty > maxQuantityLimit || product.max_qty === 0
                  ? maxQuantityLimit
                  : product.max_qty
                : product.max_qty
            }
            language={language}
            region={region}
          />
        </div>
      );
    } else if (zoneId && !inStock && !isStorePickup) {
      if (alternativeProducts?.length > 0) {
        return (
          <div className='absolute bottom-0 left-0 right-0'>
            <ChooseAlternativeButton
              alternativeProducts={alternativeProducts}
              language={language}
              region={region}
            />
          </div>
        );
      }
    } else {
      return (
        <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
          <ShippingMethodProductCart language={language} region={region} />
        </div>
      );
    }
  };

  const itemHtml = () => {
    return (
      <div
        className={
          'js-plp-product relative h-full rounded-lg bg-white p-2 shadow-standart'
        }
        data-position={product.__position}
        onClick={() => gtmSetSelectItem(product, language, region)}
      >
        <div className='relative'>
          {isProductDay ? (
            <div className='absolute top-4 z-[1] ltr:-left-[13px] rtl:-right-[13px]'>
              <ProductDayIcon className='rtl:rotate-180' />
              <span
                className='absolute left-0 right-0 text-center text-custom-xs font-bold ltr:top-[5px] rtl:top-2'
                style={{
                  color: 'rgba(199, 90, 41, 1)',
                }}
              >
                {t('product_day')}
              </span>
            </div>
          ) : (
            <div className='absolute z-10'>
              {hasBundleOffer ? (
                <Image
                  src='/assets/images/product_tags/bundle_offer.png'
                  alt={'bundle product'}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-[25px] w-[70px]'
                />
              ) : (
                product?.redbox_pl_custom_image_url?.trim() && (
                  <Image
                    src={product.redbox_pl_custom_image_url}
                    alt={product.name}
                    width={84}
                    height={32}
                    className='rounded-lg object-cover'
                  />
                )
              )}
            </div>
          )}

          <Link href={getPdpUrl(product.sku, '', language, region)}>
            <Image
              src={product.image_url}
              alt={product.name}
              width={200}
              height={200}
              className={
                'mx-auto h-[160px] w-[160px] lg:h-[210px] lg:w-[200px]'
              }
            />
          </Link>

          {typeof inStock === 'undefined' ? (
            <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
              <div
                className={clsx(
                  'relative flex h-8 w-8 animate-pulse items-center justify-center',
                  'overflow-hidden rounded-full bg-blue text-white shadow-addButton'
                )}
              ></div>
            </div>
          ) : (
            buttonCondition()
          )}

          <div
            onClick={() => handleFavorite()}
            className={clsx(
              'absolute end-0 top-0 h-8 w-8',
              'z-10 flex items-center justify-center rounded-full bg-white shadow-standart'
            )}
          >
            <HydrationWrapper>
              <FavoriteButton
                productId={product.sku}
                product={product}
                language={language}
                region={region}
              />
            </HydrationWrapper>
          </div>
        </div>
        <Link href={getPdpUrl(product.sku, '', language, region)}>
          <div className='mt-2 flex flex-col items-start space-y-2'>
            {promoBadge('show')}
            <span className='line-clamp-2 h-8 text-xs ltr:pr-12 rtl:pl-12'>
              {product.name}
            </span>
            <div className='flex items-center space-x-1 sm:flex-row lg:min-h-[3em] lg:leading-normal rtl:space-x-reverse'>
              <span className='text-custom-sm font-semibold text-red lg:text-base'>
                {product?.price?.SAR?.default_formated}
              </span>
              <span className='text-xs font-bold text-gray line-through'>
                {product?.price?.SAR?.default_original_formated}
                <br />
              </span>
            </div>
            {isPrescription && (
              <div className='rounded-xl bg-orange/20 px-2 py-1 text-[9px] font-semibold text-orange lg:text-custom-xs'>
                {t('prescription_required')}
              </div>
            )}
          </div>
          <div className='mt-2 flex items-center justify-between'>
            {typeof inStock !== 'undefined' &&
              (zoneId && !inStock && !isStorePickup ? (
                <div
                  key='sold-out-tag'
                  className='me-2 flex w-max items-center gap-1 rounded-xl bg-[#d4232d10] px-2 py-1 text-custom-xs font-semibold text-red'
                >
                  <CircleCloseIcon />
                  {t('not_available')}
                </div>
              ) : (
                deliverType()
              ))}

            {reviewSection()}
          </div>
        </Link>
        <div
          onClick={() => handleFavorite()}
          className={clsx(
            'absolute top-2 h-7 w-7 ltr:right-2 rtl:left-2',
            'z-10 flex items-center justify-center rounded-full'
          )}
        >
          <HydrationWrapper>
            <FavoriteButton
              productId={product.sku}
              product={product}
              language={language}
              region={region}
            />
          </HydrationWrapper>
        </div>
      </div>
    );
  };

  const dualColumnMobileHtml = () => {
    return (
      <div
        className={
          'relative flex space-x-4 rounded-lg bg-white p-2 shadow-standart lg:flex-row rtl:space-x-reverse'
        }
      >
        <div className='relative'>
          {isProductDay ? (
            <div className='absolute top-4 z-[1] ltr:-left-[13px] rtl:-right-[13px]'>
              <ProductDayIcon className='rtl:rotate-180' />
              <span
                className='absolute left-0 right-0 text-center text-custom-xs font-bold ltr:top-[5px] rtl:top-2'
                style={{
                  color: 'rgba(199, 90, 41, 1)',
                }}
              >
                {t('product_day')}
              </span>
            </div>
          ) : (
            <div className='absolute z-10'>
              {hasBundleOffer ? (
                <Image
                  src='/assets/images/product_tags/bundle_offer.png'
                  alt={'bundle product'}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-[25px] w-[70px]'
                />
              ) : (
                product?.redbox_pl_custom_image_url?.trim() && (
                  <Image
                    src={product.redbox_pl_custom_image_url}
                    alt={product.name}
                    width={84}
                    height={32}
                    className='rounded-lg object-cover'
                  />
                )
              )}
            </div>
          )}
          <Link
            href={getPdpUrl(product.sku, '', language, region)}
            className='flex h-[106px] w-[100px]'
          >
            <Image
              src={product.image_url}
              alt={product.name}
              width={200}
              height={200}
              className={'h-full w-full'}
            />
          </Link>
        </div>
        <Link href='/'>
          <div className='flex flex-col items-start'>
            <div className='flex items-center justify-between'>
              {reviewSection()}
            </div>

            <span className='mt-2 text-xs'>{product.name}</span>
            <div className='mt-2 flex items-center space-x-1 rtl:space-x-reverse'>
              <span className='text-custom-base font-bold text-red lg:text-base'>
                {product?.price?.SAR?.default_formated}
              </span>
              <span className='text-custom-base font-bold text-gray line-through'>
                {product?.price?.SAR?.default_original_formated}
                <br />
              </span>
            </div>
            {isPrescription && (
              <div className='rounded-xl bg-orange/20 px-2 py-1 text-[9px] font-semibold text-orange lg:text-custom-xs'>
                {t('prescription_required')}
              </div>
            )}
          </div>
          <div className='mt-[23px] flex items-center space-x-1 rtl:space-x-reverse'>
            {promoBadge('show')}

            {typeof inStock !== 'undefined' &&
              (zoneId && !inStock && !isStorePickup ? (
                <div
                  key='sold-out-tag'
                  className='me-2 flex w-max items-center gap-1 rounded-xl bg-[#d4232d10] px-2 py-1 text-custom-xs font-semibold text-red'
                >
                  <CircleCloseIcon />
                  {t('not_available')}
                </div>
              ) : (
                deliverType()
              ))}
          </div>
        </Link>

        {typeof inStock === 'undefined' ? (
          <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
            <div
              className={clsx(
                'relative flex h-8 w-8 animate-pulse items-center justify-center',
                'overflow-hidden rounded-full bg-blue text-white shadow-addButton'
              )}
            ></div>
          </div>
        ) : (
          <div className='absolute bottom-3 ltr:right-3 rtl:left-3'>
            {buttonCondition()}
          </div>
        )}
      </div>
    );
  };

  const dualColumnHtml = () => {
    return (
      <div
        className={
          'relative flex flex-col space-x-4 rounded-lg bg-white p-3 shadow-standart lg:flex-row rtl:space-x-reverse'
        }
      >
        <div className='relative'>
          {isProductDay ? (
            <div className='absolute top-6 z-[1] ltr:-left-[17px] rtl:-right-[17px]'>
              <ProductDayIcon className='rtl:rotate-180' />
              <span
                className='absolute left-0 right-0 text-center text-custom-xs font-bold ltr:top-[5px] rtl:top-2'
                style={{
                  color: 'rgba(199, 90, 41, 1)',
                }}
              >
                {t('product_day')}
              </span>
            </div>
          ) : (
            <div className='absolute z-10'>
              {hasBundleOffer ? (
                <Image
                  src='/assets/images/product_tags/bundle_offer.png'
                  alt={'bundle product'}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-[25px] w-[70px]'
                />
              ) : (
                product?.redbox_pl_custom_image_url?.trim() && (
                  <Image
                    src={product.redbox_pl_custom_image_url}
                    alt={product.name}
                    width={84}
                    height={32}
                    className='rounded-lg object-cover'
                  />
                )
              )}
            </div>
          )}
          <Link href={getPdpUrl(product.sku, '', language, region)}>
            <Image
              src={product.image_url}
              alt={product.name}
              width={200}
              height={200}
              className={'mx-auto h-[138px] w-[130px]'}
            />
          </Link>
        </div>
        <Link href={getPdpUrl(product.sku, '', language, region)}>
          <div className='flex flex-col items-start'>
            <div className='flex w-full items-center justify-between'>
              {promoBadge('hidden')}
              <div className='flex items-center space-x-1 ltr:right-3 rtl:left-3 rtl:space-x-reverse'>
                {reviewSection()}
              </div>
            </div>

            <span className='mt-3 line-clamp-2 h-8 text-xs ltr:pr-12 rtl:pl-12'>
              {product.name}
            </span>

            <div className='mt-2 flex items-center space-x-1 sm:flex-row lg:min-h-[3em] lg:leading-normal rtl:space-x-reverse'>
              <span className='text-custom-sm font-semibold text-red lg:text-base'>
                {product?.price?.SAR?.default_formated}
              </span>
              <span className='text-xs font-bold text-gray line-through'>
                {product?.price?.SAR?.default_original_formated}
                <br />
              </span>
            </div>
            {isPrescription && (
              <div className='mt-2 rounded-xl bg-orange/20 px-2 py-1 text-[9px] font-semibold text-orange lg:text-custom-xs'>
                {t('prescription_required')}
              </div>
            )}
          </div>
          <div className='mt-5 flex items-center justify-between'>
            {typeof inStock !== 'undefined' &&
              (zoneId && !inStock && !isStorePickup ? (
                <div
                  key='sold-out-tag'
                  className='me-2 flex w-max items-center gap-1 rounded-xl bg-[#d4232d10] px-2 py-1 text-custom-xs font-semibold text-red'
                >
                  <CircleCloseIcon />
                  {t('not_available')}
                </div>
              ) : (
                deliverType()
              ))}
          </div>
        </Link>

        {typeof inStock === 'undefined' ? (
          <div className='absolute bottom-0 ltr:right-0 rtl:left-0'>
            <div
              className={clsx(
                'relative flex h-8 w-8 animate-pulse items-center justify-center',
                'overflow-hidden rounded-full bg-blue text-white shadow-addButton'
              )}
            ></div>
          </div>
        ) : (
          <div className='absolute bottom-3 ltr:right-3 rtl:left-3'>
            {buttonCondition()}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {dualColumn ? (
        <>
          <div className='lg:hidden'>{dualColumnMobileHtml()}</div>
          <div className='hidden lg:block'>{dualColumnHtml()}</div>
        </>
      ) : (
        itemHtml()
      )}
    </>
  );
}
